"use client"

import { useEffect } from "react"

const forwardVhToStyles = () => {
  const vh = (window.visualViewport?.height || window.innerHeight) * 0.01
  if ((window as any).lastVH === vh) return
  ;(window as any).lastVH = vh

  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

export const useForwardVHtoStyles = () => {
  useEffect(() => {
    forwardVhToStyles()

    window.addEventListener("resize", forwardVhToStyles)
    window.visualViewport?.addEventListener("resize", forwardVhToStyles)
    return () => {
      window.removeEventListener("resize", forwardVhToStyles)
      window.visualViewport?.removeEventListener("resize", forwardVhToStyles)
    }
  })
}
