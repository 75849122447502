"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import dynamic from "next/dynamic";
const PostHogPageView = dynamic(() => import("./posthog-pageview"), {
  ssr: false
});
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "identified_only",
    capture_pageview: false // Disable automatic pageview capture, as we capture manually
  });
}
export interface IPostHogProvider {
  children?: React.ReactNode;
}
export const CSPostHogProvider = (props: IPostHogProvider) => {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="CSPostHogProvider" data-sentry-source-file="posthog-provider.tsx">
      {props.children}
      <PostHogPageView data-sentry-element="PostHogPageView" data-sentry-source-file="posthog-provider.tsx" />
    </PostHogProvider>;
};
export default CSPostHogProvider;