"use client"

import { api, useAuthed } from "shared/api"
import { useEffect } from "react"
import { getGAClientId, getGAMeasurementId } from "shared/lib"

export const useSaveGaClientId = () => {
  const authed = useAuthed()

  useEffect(() => {
    if (!authed) return

    const clientId = getGAClientId()
    const measurementId = getGAMeasurementId()

    if (!clientId || !measurementId) return

    let timeout: NodeJS.Timeout

    const updateCustomerAnalytics = async () => {
      try {
        await api.customer.customerUpdateGoogleAnalytics({
          clientId,
          measurementId,
        })
      } catch (error) {
        timeout = setTimeout(updateCustomerAnalytics, 500)
      }
    }

    updateCustomerAnalytics()

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [authed])
}
