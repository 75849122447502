import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../shared/assets/fonts/OpenSansCondensed-Bold.woff\",\"weight\":\"bold\"},{\"path\":\"../shared/assets/fonts/OpenSansCondensed-Bold.woff2\",\"weight\":\"bold\"},{\"path\":\"../shared/assets/fonts/OpenSansCondensed-Light.woff\",\"weight\":\"normal\"},{\"path\":\"../shared/assets/fonts/OpenSansCondensed-Light.woff2\",\"weight\":\"normal\"},{\"path\":\"../shared/assets/fonts/OpenSansCondensed-LightItalic.woff\",\"weight\":\"normal\",\"style\":\"italic\"},{\"path\":\"../shared/assets/fonts/OpenSansCondensed-LightItalic.woff2\",\"weight\":\"normal\",\"style\":\"italic\"}],\"variable\":\"--font-open-sans-condensed\",\"display\":\"swap\"}],\"variableName\":\"open_sans_condensed\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto_Condensed\",\"arguments\":[{\"variable\":\"--font-roboto-condensed\",\"subsets\":[\"cyrillic\",\"latin\"],\"style\":[\"normal\",\"italic\"],\"weight\":[\"300\",\"400\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"roboto_condensed\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"cyrillic\",\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\",\"italic\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"open_sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutClient"] */ "/app/src/app/layout.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager","sendGTMEvent"] */ "/app/src/shared/lib/analytics/gtm/next-gtm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/app/src/shared/lib/analytics/posthog/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrikaLocation"] */ "/app/src/shared/lib/analytics/yandex/yandex-metrika-location.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalsRoot"] */ "/app/src/shared/lib/use-modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/styles/app.scss");
