"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { yandexMetrika } from "shared/lib/analytics/yandex/counter";
export interface IYandexMetrikaLocation {}
export const YandexMetrikaLocation = (props: IYandexMetrikaLocation) => {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    yandexMetrika.hit(window.location.href);
  }, [pathName, searchParams]);
  return null;
};