"use client";

import React, { useEffect } from "react";
import Script from "next/script";
import { isProduction, LocalStorage, useForwardVHtoStyles, useUpdateCustomerPostHog } from "shared/lib";
import * as amplitude from "@amplitude/analytics-browser";
import * as process from "process";
import { useCheckTelegramAuth, useOneTimeAuth } from "features/login";
import { useSaveGaClientId } from "entity/analytics";
if (typeof window !== "undefined" && isProduction && process.env.NEXT_PUBLIC_AMPLITUDE_APIKEY) {
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_APIKEY);
}
export const LayoutClient = () => {
  useForwardVHtoStyles();
  useCheckTelegramAuth();
  useOneTimeAuth();
  useSaveGaClientId();
  useUpdateCustomerPostHog();
  return <>
      {/*<Script src="//code.jivo.ru/widget/vrnSi5tnRB" strategy="lazyOnload" />*/}

      <AmplitudeScript data-sentry-element="AmplitudeScript" data-sentry-source-file="layout.client.tsx" />
      <VkPixel data-sentry-element="VkPixel" data-sentry-source-file="layout.client.tsx" />
      <UtmParser data-sentry-element="UtmParser" data-sentry-source-file="layout.client.tsx" />
      <YandexMetrikaUrlParser data-sentry-element="YandexMetrikaUrlParser" data-sentry-source-file="layout.client.tsx" />
    </>;
};
const AmplitudeScript = () => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
    }
  }, []);
  return null;
};
const VkPixel = () => <>
    <Script src="https://vk.com/js/api/openapi.js?169" strategy="lazyOnload" onLoad={() => {
    ;
    (window as any).VK?.Retargeting.Init("VK-RTRG-1779862-gEeew");
    (window as any).VK?.Retargeting.Hit();
  }} data-sentry-element="Script" data-sentry-source-file="layout.client.tsx" />
    <noscript>
      <img src="https://vk.com/rtrg?p=VK-RTRG-1779862-gEeew" style={{
      position: "fixed",
      left: -9999
    }} alt="" />
    </noscript>
  </>;
const UtmParser = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utm: {
      [key: string]: string;
    } = {};
    [...searchParams.keys()].filter(it => it.startsWith("utm")).forEach(it => {
      utm[it] = searchParams.get(it)!;
    });
    if (Object.keys(utm).length > 0) {
      LocalStorage.setItem("utm", JSON.stringify(utm));
    }
  }, []);
  return null;
};
const YandexMetrikaUrlParser = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const yclid = searchParams.get("yclid")!;
    if (yclid) {
      LocalStorage.setItem("yclid", yclid);
    }
  }, []);
  return null;
};