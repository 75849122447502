"use client"

import posthog from "posthog-js"
import { useEffect, useRef } from "react"
import { api, useAuthed } from "shared/api"

export const useUpdateCustomerPostHog = () => {
  const isAuthed = useAuthed()
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (isAuthed && isFirstRender.current) {
      isFirstRender.current = false
      api.customer.customerUpdatePostHog({
        value: posthog.get_distinct_id(),
      })
    }
  }, [isAuthed])

  return null
}
